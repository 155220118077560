<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="title" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

import { Modal } from 'bootstrap'

export default {
    data() {
        return {
            id: 'modalbox' + Date.now(),
            title: undefined,
            el: undefined,
            //modalInstance: undefined
        }
    },
    mounted() {
        this.el = document.getElementById(this.id);
        this.modalInstance = new Modal(this.el);

        var app = this;

        this.el.addEventListener('hidden.bs.modal', function () {
            app.$emit('hide');
        });


    },
    methods: {
        open(opts = {}) {
            this.title = opts.title;
            this.modalInstance.show();
        },

        close() {
            this.modalInstance.hide();
        },
    },
}
</script>
