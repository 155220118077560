<template>
    <form autocomplete="on">
        <div class="form-floating form-floating-sm mb-3">
            <input type="text" class="form-control" id="name" name="name" v-model="name" autocomplete="on" :disabled="form_disabled" />
            <label for="name">Имя *</label>
            <span v-if="v$.name.$error" class="form-text text-danger">
                Нам нужно знать, как к вам обращаться
            </span>
        </div>
        <div class="form-floating form-floating-sm mb-3">
            <input type="text" class="form-control" id="email" name="email" v-model="email" autocomplete="on" :disabled="form_disabled" />
            <label for="email">E-mail *</label>
            <span v-if="v$.email.$error" class="form-text text-danger">
                Некорректный e-mail
            </span>
        </div>
        <div class="form-floating form-floating-sm mb-4">
            <input type="text" class="form-control" id="tel" name="tel" v-model="tel" autocomplete="on" :disabled="form_disabled" />
            <label for="tel">Телефон</label>
            <span v-if="v$.tel.$error" class="form-text text-danger">
                Это непохоже на телефонный номер
            </span>
        </div>
        <div class="mb-4">
            <div class="row">
                <div class="col-12">
                    <h5><span class="icon-receipt"></span> Электронный чек</h5>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    Выберите, где вы купили продукты MAMA&nbsp;BRANDS:
                </div>
            </div>
            <div class="row align-items-center mb-2" style="text-align:left">
                <div class="col-6 text-center">
                    <input class="form-check-input align-middle me-4" type="radio" name="store" id="store-wb" @change="storeChange(1)" :checked="store == 1" :disabled="form_disabled" />
                    <img src="~/../assets/images/wildberries.svg" class="store-img" @click="!form_disabled && storeChange(1)" />
                </div>
                <div class="col-6 text-center">
                    <input class="form-check-input align-middle me-4" type="radio" name="store" id="store-ozon" @change="storeChange(2)" :checked="store == 2" :disabled="form_disabled" />
                    <img src="~/../assets/images/ozon.svg" class="store-img" @click="!form_disabled && storeChange(2)" />
                </div>
                <div v-if="v$.store.$error" class="form-text text-danger text-center">
                    Выберите один из вариантов
                </div>
            </div>
            <div v-if="store==1">
                <div style="text-align:left">
                    <p>
                        Зайдите на сайт <a href="https://www.wildberries.ru">www.wildberries.ru</a> <span class="icon-right"></span> <a href="https://www.wildberries.ru/lk">Профиль</a> <span class="icon-right"></span> <a href="https://www.wildberries.ru/lk/receipts/get?count=10" target="_blank">Электронные чеки</a>
                        <span class="icon-right"></span><span class="wb-receipt align-middle"></span> Посмотреть чек <span class="icon-right"></span> Скопируйте адрес ссылки и вставьте в форму
                    </p>
                </div>
                <div class="form-floating form-floating-sm mb-3">
                    <input type="text" class="form-control" id="wb_url" v-model="url" :disabled="form_disabled" />
                    <label for="wb_url">URL чека</label>
                    <span v-if="v$.url.$error" class="form-text text-danger">
                        Должно быть что-то, похожее на https://f.wb.ru/r/902390823/7296d3vabb84461ac76jbf65lmg4bj93
                    </span>
                </div>
            </div>
            <div v-if="store==2">
                <div style="text-align:left">
                    <p>
                        Зайдите на сайт <a href="https://ozon.ru">ozon.ru</a> <span class="icon-right"></span> <a href="https://www.ozon.ru/my/main">Профиль</a> <span class="icon-right"></span> <a href="https://www.ozon.ru/my/e-check" target="_blank">Электронные чеки</a>
                        <span class="icon-right"></span> Скачать <span class="icon-right"></span> Загрузите скачанный pdf файл в форму
                    </p>
                </div>
                <div class="form-floating form-floating-sm mb-3">
                    <div class="mb-3">
                        <input class="form-control" type="file" id="formFile" ref="chequeFileInput"
                               accept="application/pdf" @change="onChangeFile" :disabled="form_disabled" />
                        <span v-if="v$.chequeFile.$error" class="form-text text-danger">
                            Необходимо выбрать pdf файл с чеком
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-4 d-flex justify-content-center" style="flex-wrap: wrap">
            <div id="g-recaptcha-container"></div>
            <div v-if="v$.recaptcha_token.$error && recaptcha_loaded" class="form-text text-danger w-100">
                Подтвердите, что вы не робот
            </div>
            <div v-if="v$.recaptcha_loaded.$error" class="form-text text-danger w-100">
                Дождитесь загрузки виджета "Я не робот" или перезагрузите страницу
            </div>
        </div>
        <div class="raw col-12 text-center">
            <action-button ref="sendChequeButton" @click.prevent="sendCheque" :disabled="form_disabled">
                Отправить
            </action-button>
        </div>
        <modal-box ref="modalBox">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4>
                    {{ modalMsg }}
                </h4>
                <span>{{ modalDescr }}</span>
            </div>
        </modal-box>
    </form>
</template>

<script>
    /* global grecaptcha:false */

    import useVuelidate from '@vuelidate/core'
    import { required, url, minLength, maxLength, helpers, email, requiredIf/*, or*/ } from '@vuelidate/validators'
    import ActionButton from "./ActionButton.vue";
    import ModalBox from "./ModalBox.vue";
    import axios from 'axios';

    const isValidFileSize = (options = {}) => {
        return helpers.withParams(options, value => {
            if (!value) {
                return true
            }
            const fileSizeinKb = value.size / 1024
            const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
            return size <= options.maxFileSizeInB * 1024
        })
    }
    const tel_regexp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d- ]{7,10}$/;
    const tel_is_valid = (value) => !helpers.req(value) || value.match(tel_regexp);
    const wb_url = (value) => !helpers.req(value) || /^https:\/\/f\.wb\.ru\/r\//.test(value);
    const is_dev = process.env.NODE_ENV == 'development';
    const endpoint = is_dev ? 'https://localhost:5001/receipt/' : 'https://mamabrands.promo.support/receipt/';
    export default {
        name: 'ChequePanel',
        components: {
            'action-button': ActionButton,
            'modal-box': ModalBox
        },
        setup() {
            return { v$: useVuelidate() }
        },
        mounted() {
            let app = this;
            let interval = setInterval(() => {
                if (window["recaptcha_loaded"]) {
                    grecaptcha.render("g-recaptcha-container", {
                        "sitekey": "6Lf4iIMgAAAAAPss9yl5yYkiZ3y7IIzotyjoBq5v"
                    });
                    app.recaptcha_loaded = true;
                    clearInterval(interval);
                }
            }, 500);
        },
        data() {
            return {
                store: null,
                name: null,
                email: null,
                tel: null,
                url: null,
                chequeFile: null,
                modalMsg: undefined,
                modalDescr: undefined,
                recaptcha_loaded: null,
                recaptcha_token: null,
                form_disabled: false
            }
        },
        validations() {
            return {
                store: {
                    required
                },
                name: { required, minLength: minLength(1), maxLength: maxLength(64) },
                email: { required, email },
                tel: { tel_is_valid },
                url: {
                    required: requiredIf(function () {
                        return this.store == 1;
                    }),
                    url,
                    wb_url: wb_url
                },
                chequeFile: {
                    required: requiredIf(function () {
                        return this.store == 2;
                    }),
                    isValidFileSize: isValidFileSize({ maxFileSizeInB: 5 })
                },
                recaptcha_token: { valid: required/*or(required, () => is_dev)*/ },
                recaptcha_loaded: { required }
            }
        },
        methods: {
            onChangeFile(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }

                this.chequeFile = files[0];
            },
            sendCheque() {
                if (this.recaptcha_loaded === null) {
                    this.v$.$touch();
                } else {
                    this.recaptcha_token = grecaptcha.getResponse();
                } 
               
                if (!this.recaptcha_token) {
                    let app = this;
                    let intervalID = setInterval(() => {
                        if (!this.recaptcha_loaded) {
                            return;
                        }
                        let recaptcha_token = grecaptcha.getResponse();
                        if (recaptcha_token) {
                            app.recaptcha_token = recaptcha_token;
                            app.v$.recaptcha_token.$dirty = false;
                            clearInterval(intervalID);
                        }
                    }, 1000);
                }

                this.v$.$touch();

                if (this.v$.$invalid) {
                    return;
                }

                this.$refs.sendChequeButton.start();

                let formData = new FormData();

                formData.append('store', this.store);
                formData.append('name', this.name);
                formData.append('email', this.email);
                formData.append('tel', this.tel);
                formData.append('recaptchaToken', this.recaptcha_token);

                let action;
                if (this.store == 1) {
                    formData.append('url', this.url);
                    action = 'wb'
                }
                else {
                    formData.append("file", this.chequeFile);
                    action = 'ozon'
                }

                let app = this;
                this.form_disabled = true;
                axios(
                    {
                        method: 'post',
                        url: endpoint + action,
                        data: formData,
                        timeout: 10000,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then(response => {
                        app.modalMsg = response.data.message;
                        app.modalDescr = response.data.description;

                        app.$refs.modalBox.open();
                        app.$refs.sendChequeButton.stop();

                        app.v$.$reset();

                        if (!is_dev) {
                            if (app.store == 1) {
                                app.url = null;
                            }
                            if (app.store == 2) {
                                app.$refs.chequeFileInput.value = null
                            }
                        }
                    })
                    .catch(data => {
                        let response = data.response;
                        if (data.code == "ERR_NETWORK") {
                            app.modalMsg = "🤕 Нет соединения с интернетом или сервис недоступен";
                            app.modalDescr = "Проверьте соединение и повторите попытку";
                        } else if (data.code == "ECONNABORTED") {
                            app.modalMsg = "⏰ Таймаут соединения";
                            app.modalDescr = "Вероятно, сервер сильно загружен. Повторите позже";
                        }
                        else if (response.data) {
                            app.modalMsg = response.data.message;
                            app.modalDescr = response.data.description;
                        }
                        else {
                            app.modalMsg = "Ошибка " + response.status;
                            app.modalDescr = "";
                        }
                        app.$refs.modalBox.open();
                        app.$refs.sendChequeButton.stop();
                    })
                    .finally(() => {
                        app.form_disabled = false;
                        grecaptcha.reset();
                    })
            },
            //sendChequePhoto() {
            //    this.v$.chequeFile.$touch();
            //    if (this.v$.chequeFile.$invalid) {
            //        return;
            //    }
            //    this.$refs.sendChequePhotoButton.start();
            //    let app = this;
            //    setTimeout(() => {
            //        app.modalMsg = "Чек отправлен на обработку";
            //        app.$refs.modalBox.open();
            //        app.$refs.sendChequePhotoButton.stop();
            //        //clean
            //        app.v$.chequeFile.$reset();
            //        app.$refs.chequeFileInput.value = null;
            //    }, 5000);
            //},
            storeChange(store) {
                this.store = store;
            },
            blockForm() {

            }
        }
    }
</script>

<style scoped>
    img.store-img {
        max-width: 60%;
        cursor: pointer;
    }

    .wb-receipt {
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2220%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M0%2017.6c0%201.05.806%201.9%201.8%201.9h12.073L18%2015.143V2.4c0-1.05-.806-1.9-1.8-1.9H1.8C.806.5%200%201.35%200%202.4v15.2zM1.8%202.4h14.4v10.45h-2.7c-.994%200-1.8.85-1.8%201.9v2.85H1.8V2.4zm11.7%2012.35h2.326L13.5%2017.206V14.75zm-9-1.9v1.9h5.4v-1.9H4.5zm0-1.9v-1.9h9v1.9h-9zm0-5.7v1.9h9v-1.9h-9z%22%20fill%3D%22%23CB11AB%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: center;
        width: 20px;
        height: 20px;
        font-size: 0;
        line-height: 1;
        display: inline-block;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .modal-body {
        padding-bottom: 2rem;
    }
</style>
